<template>
    <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark" aria-label="Main navigation">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">SHAPE BLADE</a>
            <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation" v-on:click="showMenu()" >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div  :class="{ 'navbar-collapse offcanvas-collapse': !_menu,  'navbar-collapse collapse show': _menu}" id="navbarsExampleDefault">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item" v-if="enabledFull || isMerchant">
                        <a class="nav-link" href="/orders" :class="selected == 'orders' ? 'active' : ''">Orders</a>
                    </li>
                    <li class="nav-item" v-if="(enabledFull || isMerchant)">
                        <a class="nav-link" href="/analytics" :class="selected == 'analytics' ? 'active' : ''">Analytics</a>
                    </li>
                    <li class="nav-item" v-if="enabledFull && !_mobile">
                        <a class="nav-link" href="/stat" :class="selected == 'stat' ? 'active' : ''">Statistics</a>
                    </li>
                    <li class="nav-item" v-if="enabledFull && !_mobile">
                        <a class="nav-link" href="/accounts" :class="selected == 'accounts' ? 'active' : ''">Accounts</a>
                    </li>
                    <li class="nav-item" v-if="enabledFull && !_mobile">
                        <a class="nav-link" href="/entries" :class="selected == 'entries' ? 'active' : ''">Journal entries</a>
                    </li>
                    <li class="nav-item" v-if="enabledFull && !_mobile">
                        <a class="nav-link" href="/agents" :class="selected == 'agents' ? 'active' : ''">Agents</a>
                    </li>
                    <li class="nav-item" v-if="enabledFull">
                        <a class="nav-link" href="/payouts" :class="selected == 'payouts' ? 'active' : ''">Payouts</a>
                    </li>
                    <li class="nav-item" v-if="enabledFull && !_mobile">
                        <a class="nav-link" href="/users" :class="selected == 'users' ? 'active' : ''">Users</a>
                    </li>
                </ul>
                <div class="d-flex" role="search" v-if="!_mobile">  
                    <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                        <a class="me-3 py-2 text-decoration-none text-light" href="#">{{ username }}</a>
                    </nav>   
                    <button v-on:click="logout()" class="btn btn-outline-success" type="submit">Logout</button>
                </div>
            </div>
        </div>
    </nav>

    <main class="main-conteiner">
        <slot></slot>
    </main>
</template>

<style>
.main-conteiner {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    props: {
        selected: String
    },
    data() {
      return {
        _mobile: false,
        _menu: false
      }
    },
    methods: {
        showMenu() {
            this._menu = !this._menu
        },
        logout()
        {
            localStorage.removeItem('user_token');
            localStorage.removeItem('user_rights')
            localStorage.removeItem('user_profile_username')
            localStorage.removeItem('user_profile_firstname')
            localStorage.removeItem('user_profile_lastname')
            window.dispatchEvent(new CustomEvent('logout-data-localstorage-event', {

            }));
        }
    },
    computed: {
        enabledFull: function() {
            var rights = localStorage.getItem('user_rights');

            if(rights && rights.includes('admin'))
            {
                return true
            }

            if(rights && rights.includes('user'))
            {
                return true
            }
            return false
        },
        isMerchant: function() {
            var rights = localStorage.getItem('user_rights');

            if(rights && rights.includes('merchant'))
            {
                return true
            }
            return false
        },
        isAdmin: function() {
            var rights = localStorage.getItem('user_rights');

            if(rights && rights.includes('admin'))
            {
                return true
            }
            return false
        },
        username: function() {
            let user_profile_username = localStorage.getItem('user_profile_username');
            let user_profile_firstname = localStorage.getItem('user_profile_firstname');
            let user_profile_lastname = localStorage.getItem('user_profile_lastname');

            return `${user_profile_username} (${user_profile_firstname} ${user_profile_lastname})`
        }
    },
    async mounted() {
        this._mobile = vueDetectMobile()
    }
  }
</script>